.body-main-container{
    width: auto;
    height:50%;
    margin: 4px;
}
.main {
    text-align: center;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .Main_title {
   font-size: 42px;
  }
  .picture{
    height: 90%;
  }
  .title{
    font-size:medium;
    cursor: pointer;
  }
  
  .image {
    height: 300px;
    width: auto;
    font-size: 20px;
    margin: 10px;
    display: inline-block;
    line-height: 20px; 
  }
  .image img{
      height: 100%;
      width: 300px;
  }
  
  
  .image-container {
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 90%;
    grid-template-columns: auto auto auto;
  }
  
  .prev, .next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
  }
  
  .next {
    margin-left: 5px;
  }
  
  .prev {
    margin-right: 5px;
  }
  
  .prev:hover, .next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }