.footer--main-container{
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 15px;
    text-align: center;
    border-radius: 0.6em;
    align-self: center;
}
/* .footer_logo{
    
} */
.footer_logo img{
    width: 95%;
    height: 300px;
    margin: 4px;
}
/* .footer_text{
    
} */