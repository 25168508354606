.top{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* align-self: center; */
    border-radius: 0.6em;
    
}
.Logo{
    height: 100px;
    margin-right: 10px;
    width: 10%;
}
.Menu{
    height: 50px;
    padding: 3px;
    margin-top: 6px;
    max-width: 80%;
    margin-right: 8px;
}