.Login{
    background-color: rgb(15, 15, 209);
    border-radius: 0.7em;
    min-width: 100px;
    min-height: 30px;
    margin: 4px;
    margin-left: 100px; 
    color: white;
    text-align: center;
}
.SignUp{
    background-color: rgb(15, 15, 209);
    border-radius: 0.7em;
    min-width: 100px;
    min-height: 30px;
    margin: auto;
    color: white;
    text-align: center;
    margin-right: 10px;
}
.google_translate_element{
    background-color: bisque;
}